<template>
  <div class="container">
    <pre style="display: none">{{ pagination | pretty_json }}}</pre>
    <h1>Lista de Metas</h1>
    <div class="card">
      <div class="card-body">
        <b-table striped hover :items="measures" :fields="fields">
          <template #cell(actions)="row">
            <Actions
              :item="row.item"
              :view_show="$can('measure:view')"
              :view_edit="$can('measure:edit')"
              :view_delete="$can('measure:delete')"
              view_to="measures_show"
              edit_to="measures_edit"
              @delete="delete_measure"
            />
          </template>
        </b-table>
      </div>
    </div>
    <div class="overflow-auto mt-4">
      <b-pagination-nav
        v-model="page"
        :link-gen="linkGen"
        :number-of-pages="pagination.last_page"
        use-router
      ></b-pagination-nav>
    </div>
  </div>
</template>

<script>
import Actions from "@/components/Table/Actions.vue";
import { mapState, mapActions } from "vuex";

export default {
  components: { Actions },
  data() {
    return {
      page: 1
    };
  },
  computed: {
    ...mapState("measures", ["measures", "pagination"]),
    fields() {
      return [
        { key: "id", label: "#" },
        { key: "name", label: "Nome" },
        { key: "actions", label: "#", class: "col-1" }
      ];
    }
  },
  methods: {
    ...mapActions("measures", ["ActionGetAll"]),
    delete_measure(measure) {
      return measure;
    },

    linkGen(pageNum) {
      return pageNum === 1 ? "?" : `?page=${pageNum}`;
    }
  },
  async created() {
    if (this.$route.query.page > 1) {
      this.page = this.$route.query.page;
    }
    await this.ActionGetAll({ page: this.page });
  }
};
</script>

<style></style>
